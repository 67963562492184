import request from "@/utils/request";

// 采购询价单
export function purchaseInquiryOrderList(params) {
  return request({ url: `/purchase_inquiry_orders/`, method: "get", params });
}

export function purchaseInquiryOrderDetail(params) {
  return request({ url: `/purchase_inquiry_orders/${params.id}/`, method: "get", params });
}

export function purchaseInquiryOrderNumber(params) {
  return request({ url: `/purchase_inquiry_orders/number/`, method: "get", params });
}

export function purchaseInquiryOrderCreate(data) {
  return request({ url: `/purchase_inquiry_orders/`, method: "post", data });
}

export function purchaseInquiryOrderUpdate(data) {
  return request({ url: `/purchase_inquiry_orders/${data.id}/`, method: "put", data });
}

export function purchaseInquiryOrderDestroy(data) {
  return request({ url: `/purchase_inquiry_orders/${data.id}/`, method: "delete", data });
}

export function purchaseInquiryOrderCommit(data) {
  return request({ url: `/purchase_inquiry_orders/${data.id}/commit/`, method: "post", data });
}

// 采购询价记录
export function purchaseInquiryTaskList(params) {
  return request({ url: `/purchase_inquiry_tasks/`, method: "get", params });
}

// 采购申请单
export function purchaseRequisitionOrderList(params) {
  return request({ url: `/purchase_requisition_orders/`, method: "get", params });
}

export function purchaseRequisitionOrderDetail(params) {
  return request({ url: `/purchase_requisition_orders/${params.id}/`, method: "get", params });
}

export function purchaseRequisitionOrderNumber(params) {
  return request({ url: `/purchase_requisition_orders/number/`, method: "get", params });
}

export function purchaseRequisitionOrderCreate(data) {
  return request({ url: `/purchase_requisition_orders/`, method: "post", data });
}

export function purchaseRequisitionOrderUpdate(data) {
  return request({ url: `/purchase_requisition_orders/${data.id}/`, method: "put", data });
}

export function purchaseRequisitionOrderDestroy(data) {
  return request({ url: `/purchase_requisition_orders/${data.id}/`, method: "delete", data });
}

export function purchaseRequisitionOrderApproval(data) {
  return request({ url: `/purchase_requisition_orders/${data.id}/approval/`, method: "post", data });
}

// 采购开单
export function purchaseOrderCreate(data) {
  return request({ url: `/purchase_orders/`, method: "post", data });
}

export function purchaseOrderList(params) {
  return request({ url: `/purchase_orders/`, method: "get", params });
}

export function purchaseOrderUpdate(data) {
  return request({ url: `/purchase_orders/${data.id}/`, method: "put", data });
}

export function purchaseOrderDestroy(data) {
  return request({ url: `/purchase_orders/${data.id}/`, method: "delete", data });
}

export function purchaseOrderDetail(params) {
  return request({ url: `/purchase_orders/${params.id}/`, method: "get", params });
}

export function purchaseOrdersCommit(data) {
  return request({ url: `/purchase_orders/${data.id}/commit/`, method: "post", data });
}

export function purchaseOrdersSubmit(data) {
  return request({ url: `/purchase_orders/${data.id}/submit/`, method: "post", data });
}

export function purchaseOrdersCancelSubmit(data) {
  return request({ url: `/purchase_orders/${data.id}/cancel_submit/`, method: "post", data });
}

export function purchaseOrdersVoid(data) {
  return request({ url: `/purchase_orders/${data.id}/void/`, method: "post", data });
}

export function purchaseFileUpload(data) {
  return request({
    url: "/purchase_files/",
    method: "post",
    data,
  });
}

// 采购退货单
export function purchaseReturnOrderCreate(data) {
  return request({ url: `/purchase_return_orders/`, method: "post", data });
}

// 退货记录
export function purchaseReturnOrdersList(params) {
  return request({ url: `/purchase_return_orders/`, method: "get", params });
}

export function purchaseReturnOrderUpdate(data) {
  return request({ url: `/purchase_return_orders/${data.id}/`, method: "put", data });
}

export function purchaseReturnOrderDestroy(data) {
  return request({ url: `/purchase_return_orders/${data.id}/`, method: "delete", data });
}

export function purchaseReturnOrdersCommit(data) {
  return request({ url: `/purchase_return_orders/${data.id}/commit/`, method: "post", data });
}

export function purchaseReturnOrdersSubmit(data) {
  return request({ url: `/purchase_return_orders/${data.id}/submit/`, method: "post", data });
}

export function purchaseReturnOrdersCancelSubmit(data) {
  return request({ url: `/purchase_return_orders/${data.id}/cancel_submit/`, method: "post", data });
}

// 退货记录详情
export function purchaseReturnOrderDetail(params) {
  return request({ url: `/purchase_return_orders/${params.id}/`, method: "get", params });
}

// 退货记录作废
export function purchaseReturnOrdersVoid(data) {
  return request({ url: `/purchase_return_orders/${data.id}/void/`, method: "post", data });
}

export function purchaseReturnFileUpload(data) {
  return request({
    url: "/purchase_return_files/",
    method: "post",
    data,
  });
}

export function purchaseInquiryFileUpload(data) {
  return request({
    url: "/purchase_inquiry_files/",
    method: "post",
    data,
  });
}

export function purchaseRequisitionFileUpload(data) {
  return request({
    url: "/purchase_requisition_files/",
    method: "post",
    data,
  });
}
